.fc-day a {
  color: #222;
}

button.fc-button-primary {
  background-color: #293d70 !important;
  border-color: #fff !important;
  padding: 0.4rem 0.7rem !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #3e5797 !important;
  }
}



.pending-icon {
  margin: 0 10px;

}

.pending {
  display: flex;
  align-items: center;
}



.fc-toolbar-title {
  font-weight: bold;
  font-size: 1.8rem !important;
  color: #00113d;
  font-family: 'Inter', sans-serif;
}

.fc-media-screen {
  width: 100%;
}

.fc-col-header-cell {
  background: #293d70;

  & a {
    color: #fff !important;
  }
}

.fc .fc-button {
  border-radius: 5px;
}
