



.font-picker {
  width: 180px;
  min-width: 180px;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
}

.font-picker-input {
  width: 100%;
  height: 32px;
  padding: 0.5rem;
  color: #333;
  box-shadow: none;
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid #d9d9d9;
}

.icon {
  width: 12px;
}

.font-picker-input:hover {
  border: 1px solid #40a9ff;
}

.show-options {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border: 1px solid #40a9ff;
}

.font-picker-options {
  position: absolute;
  width: 180px;
  display: flex;
  top: 35px;
  left: 0;
  flex-direction: column;
  height: 0;
  overflow-y: auto;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.font-picker-option {
  width: 100%;
  height: 32px;
  padding: 0.5rem;
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #fff;
}

.font-picker-option:hover {
  background-color: #f5f5f5;
}

.active {
  background-color: rgba(24, 144, 255, 0.2);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}

@keyframes slidein {
  from {
    height: 0;
  }

  to {
    height: 288px;
  }
}

@keyframes slideout {
  from {
    height: 288px;
  }

  to {
    height: 0;
  }
}
