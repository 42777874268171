// Options for TextInputWithOptions component
.options {
  background-color: #fff;
  position: absolute;
  top: 5.5rem;
  right: 0;
  border-radius: 5px;
  z-index: 10;
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__label {
    padding-left: 1.2rem;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  position: relative;
}

.select-input {
  width: 100%;
  height: 2.6rem;
  border-radius: 5px;
  border: none;

  padding: 0.5rem;
  cursor: pointer;
}

.sidebar-format {
  height: 100%;
  width: calc(35rem - 7.1rem);
  padding: 2rem;
  font-size: 1.2rem;
}

.menu-title {
  color: #fff;
  font-weight: bold;
  margin-bottom: 1.8rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 1.8rem;
}

.flex {
  display: flex;
}

// Used to cover window to close pop ups
.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

// Chromepicker

.chrome-picker {
  box-shadow: none !important;
  width: 100% !important;
  height: 50% !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 0 !important;
}

.alpha-picker {
  width: 100% !important;
}

// Resize handles
.se,
.ne,
.sw,
.nw {
  opacity: 1 !important;
}
